import * as Sentry from '@sentry/nextjs'
import type {
  GetStaticPathsContext,
  GetStaticPropsContext,
  InferGetStaticPropsType,
} from 'next'
import commerce from 'src/lib/api/commerce'
import { Layout } from '@/components'
import getSlug from 'src/lib/get-slug'

import { missingLocaleInPages } from 'src/lib/usage-warns'
import type { Page } from '@commerce/types/page'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import { StorefrontSettingsContext } from '@/stores'
import { getStorefrontSettings } from '@/utils/cms'

function Pages({
  page,
  storefrontSettings,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const router = useRouter()
  if (!page) {
    return null
  }

  return router.isFallback ? (
    <h1>Loading...</h1> // TODO (BC) Add Skeleton Views
  ) : (
    <StorefrontSettingsContext.Provider value={storefrontSettings}>
      <Layout pageType={'page'}>
        <div
          className={
            'container mt-5 mb-12 md:mt-10 md:mb-24 prose md:prose-lg lg:prose-xl max-w-none'
          }
        >
          <h2 className={'font-bold text-center text-4xl mb-5 md:mb-6'}>
            {page.name}
          </h2>
          <div className={'max-w-4xl mx-auto'}>
            {page?.body && (
              <div dangerouslySetInnerHTML={{ __html: page.body }} />
            )}
          </div>
        </div>
      </Layout>
      <NextSeo title={page.name} />
    </StorefrontSettingsContext.Provider>
  )
}

export const getStaticProps = async ({
  preview,
  params,
  locale,
  locales,
}: GetStaticPropsContext<{ pages: string[] }>) => {
  const config = { locale, locales }
  const { pages } = await commerce.getAllPages({
    config: { locale, locales },
    preview,
  })
  const path = params?.pages.join('/')
  const slug = locale ? `${locale}/${path}` : path
  const pageItem = pages.find((p: Page) =>
    p.url ? getSlug(p.url) === slug : false
  )

  const data =
    pageItem &&
    (await commerce.getPage({
      variables: { id: pageItem.id! },
      config,
      preview,
    }))

  const page = data?.page

  if (!page) {
    // TODO: Check siteredirects?
    console.log(`Missing page: ${slug}`)
    return {
      props: {
        page: null,
      },
      notFound: true,
    }
  }

  const storefrontSettings = await getStorefrontSettings()
  return {
    props: {
      page,
      storefrontSettings,
    },
    revalidate: 60 * 5,
  }
}

const existingPathsWithLocalTemplateOverrides = [
  '/help',
  '/vault',
  '/products',
  '/categories',
]

export const getStaticPaths = async ({ locales }: GetStaticPathsContext) => {
  const config = { locales }
  const { pages }: { pages: Page[] } = await commerce.getAllPages({ config })
  const [invalidPaths, log] = missingLocaleInPages()
  const paths = pages
    .map((page) => page.url)
    .filter((url) => {
      if (!url || !locales) return url
      // If there are locales, only include the pages that include one of the available locales
      if (locales.includes(getSlug(url).split('/')[0])) return url

      invalidPaths.push(url)
    })
    // Remove all paths that begin with a path in existingPathsWithLocalTemplateOverrides
    .filter((url) => {
      for (const path of existingPathsWithLocalTemplateOverrides) {
        if (url?.startsWith(path)) {
          console.debug('filtered out', url)
          return false
        }
      }
    })
  log()

  return {
    paths,
    fallback: 'blocking',
  }
}

export default Pages
